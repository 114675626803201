.nav-div {
  text-align: start !important;
}

.greeting {
  margin-right: 20px;
  font-weight: 800 !important;
  font-size: 16px !important;
  text-align: start;
}

.greeting-secondary {
  font-size: 15px !important;
  margin-right: 20px;
  font-weight: 300 !important;
}

.abyss-page-header-top-nav-menu {
  font-size: 13px !important;
}

@media screen and (min-width: 1800px) {
  .abyss-page-header-top-nav-menu {
    font-size: 20px !important;
  }

  .greeting {
    font-size: 20px !important;
  }

  .greeting-secondary {
    font-size: 18px !important;
  }
}

.abyss-c-kjMckx {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.toolbar {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.footer-links {
  color: white;
}

.footer-links:hover {
  color: white;
  text-decoration: none;
}
