.wrapper {
  width: 100%;
  margin-top: 50px;
}

@media screen and (min-width: 1600px) {
  .wrapper {
    width: 60%;
    margin: auto;
    padding-top: 30px;
  }

  .i-want-to-header {
    padding-left: 10px !important;
  }
}

.inside-wrapper {
  display: flex;
  justify-content: space-around;
}

.i-want-to-header {
  padding-left: 100px;
  margin-bottom: 10px;
  color: black !important;
  font-weight: 500 !important;
  font-size: medium;
  text-align: start;
}

.i-want-to-buttons {
  width: 100%;
  padding: 0;
  margin: 0 !important;
  list-style: none;
}

.shortcut-links {
  width: 100%;
  text-decoration: none;
}

.shortcut-button {
  height: 45px;
  width: 100%;
  margin-bottom: 4px;
  margin-top: 2px;
  padding: 12px 16px 12px 24px;
  background: #14c6c6;
  border: none;
  border: 2px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.shortcut-button-text {
  margin-right: 16px;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  text-decoration: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
