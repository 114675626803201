.react-calendar__navigation__prev2-button {
  display: none;
}

.react-calendar__navigation__prev-button {
  font-weight: 200;
  font-size: x-large;
}

.react-calendar__navigation__label {
  font-size: x-large;
  font-weight: 200;
}

.react-calendar__navigation__next2-button {
  display: none;
}

.react-calendar__navigation__next-button {
  font-weight: 200;
  font-size: x-large;
}

.react-calendar {
  padding: 5px;
  border: none;
}

.release-date-class {
  color: red;
  background-color: beige !important;
  /* font-size: x-small!important!important; */
}

.india-holiday-date-class {
  background-color: #c3c4e2 !important;
  /* font-size: small!important; */
}

.usa-holiday-date-class {
  color: red;
  background-color: aqua !important;
  /* font-size: small!important; */
}

.react-calendar__month-view {
  font-weight: bold;
}

.react-calendar__month-view button {
  border-radius: 50%;
  font-size: large;
}

.calendar-legend {
  display: flex;
  margin: auto;
  padding-top: 10px;
  justify-content: space-between;
}
.calendar-legend-second-row {
  display: flex;
  width: 80%;
  margin: auto;
  padding: 10px;
}

#release {
  border-radius: 2.5rem;
  opacity: 1;
  border: 2pt solid beige;
  width: 25pt;
  height: 25pt;
  background-color: beige;
}

#usa-holiday {
  border-radius: 2.5rem;
  opacity: 1;
  border: 2pt solid aqua;
  width: 25pt;
  height: 25pt;
  background-color: aqua;
}

#india-holiday {
  border-radius: 2.5rem;
  opacity: 1;
  border: 2pt solid #c3c4e2;
  width: 25pt;
  height: 25pt;
  background-color: #c3c4e2;
}

.calendar-ib {
  display: inline-block;
  margin-right: 2px;
}

.calendar-legend-text {
  padding-top: 5px;
  margin-right: 5px;
}
