.img-container {
  height: 105px !important;
  background: #fff !important;
}

.dashboard-img {
  padding: 5px 5px 5px 5px;
  min-width: 100% !important;
  height: 125px;
  width: 50px;
}

.roster-image {
  height: 125px;
  padding-left: 35px !important;
}

.dashboard-title {
  margin: 15px 20px 0px 100px;
  text-align: start;
}

.dashboard-header {
  color: black !important;
  font-weight: 100 !important;
  margin-left: 0px;
  margin-top: 30px;
}

.dashboard-card-container {
  width: 80%;
  margin: auto;
  padding: 0px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  text-align: start;
  /* box-shadow: 0.5px 0.5px 3px #8c8585; */
  border-radius: 8px;
}

@media screen and (min-width: 1600px) {
  .dashboard-card-container {
    justify-content: center;
  }

  .dashboard-title {
    margin-left: 280px;
  }
}

.dashboard-card {
  margin: 10px;
  width: 225px;
  height: 290px;
  transition: width 0s, height 0s;
  transition-timing-function: ease-out;
  transition-delay: 0s;
  border: 1px solid #525525 !important;
  border-radius: 4px;
  padding: 5px;
}

.dashboard-card:hover {
  cursor: pointer;
  width: 223px;
  height: 288px;
  box-shadow: 0.5px 0.5px 10px #dbc1c1;
}

.left-card {
  margin-top: 25px;
}

.dashboard-card-subcontainer {
  padding: 10px;
}

.dashboard-card-title {
  color: black !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  margin-top: 15px;
}

.dashboard-card-description {
  margin-top: 2px;
  font-size: 13px;
}
